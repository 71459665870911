import { ExamPartStatus, ExamAnswerArea } from '@/constants'
import { previousPartToolDifference } from './partHelper'

export type ExamPartForRecommendation = Pick<ExamPart, 'tools' | 'config'>

export function partRecommendations(
  parts: ExamPartForRecommendation[]
): ExamPartStatus[] {
  return parts.reduce((acc: ExamPartStatus[], _part, partIndex, parts) => {
    if (partIndex === 0) {
      acc.push(ExamPartStatus.OPEN)
      return acc
    }
    const change = previousPartToolDifference(
      parts[partIndex - 1].tools,
      parts[partIndex].tools
    )
    let previousPartOnSeparatePaper = false
    for (let i = partIndex - 1; i >= 0; i--) {
      const previousPart = parts[i]
      if (previousPart.config.answerArea === ExamAnswerArea.SEPARATE) {
        previousPartOnSeparatePaper = true
        break
      }
      if (acc[i] !== ExamPartStatus.OPEN) {
        break
      }
    }

    // row 8
    if (
      change.toolsAdded &&
      change.toolsRemoved &&
      previousPartOnSeparatePaper
    ) {
      acc.push(ExamPartStatus.HIDDEN_UNTIL_TEACHER_APPROVE)
      return acc
    }
    // row 5-7
    if (change.toolsRemoved) {
      acc.push(ExamPartStatus.HIDDEN_UNTIL_STUDENT_SUBMIT)
      return acc
    }
    // row 4
    if (change.toolsAdded && previousPartOnSeparatePaper) {
      acc.push(ExamPartStatus.TOOLS_ON_TEACHER_APPROVE)
      return acc
    }
    // row 1-3
    acc.push(ExamPartStatus.OPEN)
    return acc
  }, [])
}
