import { defineStore } from 'pinia'
import {
  groupGetById,
  groupExamsSearch,
  groupStudentsGetAll,
  groupStudentsAdd,
  groupStudentsDelete,
  groupStudentResultsGet,
} from '@/services/group'

import { PageSize, FIRST_PAGE_INDEX } from '@/constants'
import { useGroupStudentFormatAndSort } from '@/utils/composables/useGroupStudentFormatAndSort'

const BLANK_GROUP: MyGroup = {
  id: 0,
  name: '',
  curriculumId: 0,
  archivedAt: null,
  courses: [],
  exams: [],
  settings: {
    books: [],
    bookSeries: [],
    background: {
      src: '',
      type: 'color',
    },
    studentSortBy: {
      userListOrder: 'firstName',
      nameDisplayOrder: 'firstName',
    },
  },
  sharedTeachers: [],
  owner: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    avatar: { color: '', borderColor: '', textColor: '' },
  },
  students: [],
  studentResults: [],
}

export const useGroupStore = defineStore('group', {
  state: () => ({
    loadedGroup: null as MyGroup | null,
    isLoading: false,
    page: FIRST_PAGE_INDEX,
    pageSize: PageSize.NORMAL,
    totalExamsCount: 0,
  }),
  getters: {
    group(): MyGroup {
      if (!this.loadedGroup) {
        return BLANK_GROUP
      }
      return this.loadedGroup
    },
    students(): Student[] {
      return this.group.students
    },
    studentResults(): StudentResults[] {
      return this.group.studentResults
    },
    hasGroup(): boolean {
      return !!this.loadedGroup?.id
    },
  },
  actions: {
    async loadGroup(groupId: number) {
      const group: MyGroup = await groupGetById(groupId)
      group.exams = []
      group.students = []
      group.studentResults = []
      this.loadedGroup = group
    },
    async loadGroupExams() {
      if (!this.loadedGroup) {
        throw new Error('No group loaded')
      }

      const { exams, metadata } = await groupExamsSearch(this.loadedGroup.id, {
        page: this.page,
        pageSize: this.pageSize,
      })

      this.group.exams = exams
      this.totalExamsCount = metadata.count
    },
    async loadGroupStudents() {
      if (!this.loadedGroup) {
        throw new Error('No group loaded')
      }

      const students = await groupStudentsGetAll(this.loadedGroup.id)
      const studentFormatAndSort = useGroupStudentFormatAndSort()
      students.forEach(
        (student: any) =>
          (student.fullName = studentFormatAndSort.getDisplayName(student))
      )
      this.group.students = students
      this.sortStudents()
    },
    sortStudents() {
      this.group.students = useGroupStudentFormatAndSort().sortStudents(
        this.group.students
      )
    },
    async loadStudentResults(studentId: number) {
      if (!this.loadedGroup) {
        throw new Error('No group loaded')
      }

      const results = await groupStudentResultsGet(
        this.loadedGroup.id,
        studentId
      )
      this.group.studentResults = results
    },
    updateStudentsFullName() {
      const studentFormatAndSort = useGroupStudentFormatAndSort()
      this.students.forEach(
        (student) =>
          (student.fullName = studentFormatAndSort.getDisplayName(student))
      )
    },
    updateGroup(name: string, courses: { id: number; name: string }[]) {
      if (!this.loadedGroup) {
        throw new Error('No group loaded')
      }
      this.loadedGroup.name = name
      this.loadedGroup.courses = courses
    },
    updateSortBy(sortBy: NameSortAndDisplaySetting) {
      if (!this.loadedGroup) {
        throw new Error('No group loaded')
      }
      this.loadedGroup.settings.studentSortBy = sortBy
      this.sortStudents()
    },
    getStudent(studentId: number): Student | null {
      if (!this.loadedGroup) {
        throw new Error('No group loaded')
      }

      const student = this.group.students.find(
        (student: Student) => student.id === studentId
      )

      if (!student) {
        return null
      }

      return student
    },
    async addStudents(groupId: number, students: Partial<Student>[]) {
      const addedStudents = await groupStudentsAdd(groupId, {
        students,
      })

      // add students to local state to avoid unnecessary API calls to update the list
      const studentFormatAndSort = useGroupStudentFormatAndSort()
      addedStudents.forEach((student: Student) => {
        student.fullName = studentFormatAndSort.getDisplayName(student)
      })

      const existingStudentIds = this.group.students.map(
        (student) => student.id
      )
      // don't add duplicate if student already exists in the group
      const newStudents = addedStudents.filter(
        (student: Student) => !existingStudentIds.includes(student.id)
      )
      newStudents.forEach((student: Student) => {
        this.group.students.push({
          ...student,
          hasLoggedIn:
            students.find((s) => s.id === student.id)?.hasLoggedIn || false,
        })
      })
      this.sortStudents()
      await this.loadGroupExams() // need to reload exams table to update numStudents and numAssessedStudents
    },
    async removeStudents(groupId: number, studentIds: number[]) {
      await groupStudentsDelete(groupId, studentIds)

      this.group.students = this.group.students.filter(
        (student) => !studentIds.includes(student.id)
      )

      await this.loadGroupExams() // need to reload exams table to update numStudents and numAssessedStudents
    },
    async updateStudent(studentId: number, studentData: Student) {
      const student = this.group.students.find(
        (student) => student.id === studentId
      )

      if (!student) {
        throw new Error('Student not found')
      }

      student.firstName = studentData.firstName
      student.lastName = studentData.lastName
      student.email = studentData.email
      student.fullName =
        useGroupStudentFormatAndSort().getDisplayName(studentData)
      this.sortStudents()
    },
    setPendingAccountStatus(studentIds: number[]) {
      studentIds.forEach((studentId) => {
        const student = this.group.students.find(
          (student) => student.id === studentId
        )
        if (student) {
          student.hasLoggedIn = 'PENDING'
        }
      })
    },
    updateExamStatus(data: ExamStatusData) {
      const exam = this.group.exams.find((exam) => exam.id === data.examId)

      if (!exam) {
        throw new Error('Exam not found')
      }

      exam.syncFailed = data.syncFailed
      if (!data.syncFailed) {
        exam.status = data.statusId
        exam.exportedAt = data.exportedAt
        exam.externalExamId = data.externalExamId
        exam.examKey = data.examKey
      }
    },
    setLoading(isLoading: boolean) {
      this.isLoading = isLoading
    },
    setPageIndex(index: number) {
      this.page = index
    },
    setPageSize(size: number) {
      this.pageSize = size
    },
    clear() {
      this.loadedGroup = null
      this.isLoading = false
      this.page = FIRST_PAGE_INDEX
      this.pageSize = PageSize.NORMAL
      this.totalExamsCount = 0
    },
  },
})
