import { ExamAnswerArea, ExamPartStatus, ExamNetPartState } from '@/constants'

import { previousPartToolDifference } from './partHelper'

type PartValidation = {
  status: 'valid' | 'invalid' | 'info' | 'disabled'
  messages?: string[]
  examNetStatus?: ExamNetPartState
}

export type ExamPartForValidation = Pick<ExamPart, 'tools' | 'config'>

export function validatePart(
  parts: ExamPartForValidation[],
  partIndex: number
): PartValidation {
  if (partIndex === 0) {
    // first part cannot get any validation error
    return {
      status: 'valid',
      messages: [],
      examNetStatus: ExamNetPartState.WRITABLE,
    }
  }

  // If some of the previous parts is CLOSED, disable the current part (like CLOSED, OPEN, OPEN)
  const hasSomePreviousPartClosed = parts
    .slice(0, partIndex)
    .some((part) => part.tools?.state === ExamPartStatus.CLOSED)

  if (hasSomePreviousPartClosed) {
    return {
      status: 'disabled',
      messages: ['exam.partStatus.disabled'],
      examNetStatus: ExamNetPartState.PAUSED,
    }
  }

  const change = previousPartToolDifference(
    parts[partIndex - 1].tools,
    parts[partIndex].tools
  )
  const previousPartOnSeparatePaper =
    parts[partIndex - 1].config.answerArea === ExamAnswerArea.SEPARATE

  const hasNextPartNotHidden =
    partIndex + 1 < parts.length
      ? [ExamPartStatus.OPEN, ExamPartStatus.TOOLS_ON_TEACHER_APPROVE].includes(
          parts[partIndex + 1].tools?.state
        )
      : false

  const currentState = parts[partIndex].tools?.state

  // row 8 in notion table
  if (change.toolsAdded && change.toolsRemoved && previousPartOnSeparatePaper) {
    if (
      [ExamPartStatus.OPEN, ExamPartStatus.TOOLS_ON_TEACHER_APPROVE].includes(
        currentState
      )
    ) {
      return {
        status: 'invalid',
        messages: ['exam.partStatus.invalidOpen'],
      }
    }

    if ([ExamPartStatus.HIDDEN_UNTIL_STUDENT_SUBMIT].includes(currentState)) {
      const messages = ['exam.partStatus.infoRecommendHideTools']
      if (hasNextPartNotHidden) {
        messages.push('exam.partStatus.infoFollowingPartsOpen')
      }
      return {
        status: 'info',
        messages,
        examNetStatus: ExamNetPartState.HIDDEN_UNTIL_STUDENT_SUBMIT,
      }
    }
  }
  // row 5-7 in notion table
  if (change.toolsRemoved) {
    if (
      [ExamPartStatus.OPEN, ExamPartStatus.TOOLS_ON_TEACHER_APPROVE].includes(
        currentState
      )
    ) {
      return {
        status: 'invalid',
        messages: ['exam.partStatus.invalidOpen'],
      }
    }
  }

  // row 4 in notion table
  if (change.toolsAdded && previousPartOnSeparatePaper) {
    if (
      [
        ExamPartStatus.OPEN,
        ExamPartStatus.HIDDEN_UNTIL_STUDENT_SUBMIT,
      ].includes(currentState)
    ) {
      return {
        status: 'info',
        messages: ['exam.partStatus.infoRecommendHideTools'],
        examNetStatus: gaussStateToExamNetState(currentState),
      }
    }
  }

  // row 3 in notion table
  if (change.toolsAdded) {
    if ([ExamPartStatus.OPEN].includes(currentState)) {
      return {
        status: 'info',
        messages: ['exam.partStatus.infoOpen'],
        examNetStatus: ExamNetPartState.TOOLS_ON_STUDENT_SUBMIT,
      }
    }
  }

  if (
    [
      ExamPartStatus.HIDDEN_UNTIL_TEACHER_APPROVE,
      ExamPartStatus.HIDDEN_UNTIL_STUDENT_SUBMIT,
    ].includes(currentState) &&
    hasNextPartNotHidden
  ) {
    return {
      status: 'info',
      messages: ['exam.partStatus.infoFollowingPartsOpen'],
      examNetStatus: gaussStateToExamNetState(currentState),
    }
  }

  return {
    status: 'valid',
    messages: [],
    examNetStatus: gaussStateToExamNetState(currentState),
  }
}
function gaussStateToExamNetState(status: ExamPartStatus): ExamNetPartState {
  switch (status) {
    case ExamPartStatus.OPEN:
      return ExamNetPartState.WRITABLE
    case ExamPartStatus.HIDDEN_UNTIL_STUDENT_SUBMIT:
      return ExamNetPartState.HIDDEN_UNTIL_STUDENT_SUBMIT
    case ExamPartStatus.TOOLS_ON_TEACHER_APPROVE:
      return ExamNetPartState.TOOLS_ON_TEACHER_APPROVE
    case ExamPartStatus.HIDDEN_UNTIL_TEACHER_APPROVE:
      return ExamNetPartState.HIDDEN_UNTIL_TEACHER_APPROVE
    case ExamPartStatus.CLOSED:
      return ExamNetPartState.PAUSED
    default:
      return ExamNetPartState.PAUSED // or other default value as needed
  }
}
