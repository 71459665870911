import i18n from '@/i18n'
import colleagueImageUrl from '@/assets/pictures/exam-library/colleagues-exams.jpg'
import nationalImageUrl from '@/assets/pictures/exam-library/national-exams.jpg'
import courseImageUrl from '@/assets/pictures/exam-library/course-exams.jpg'
import allExamsImageUrl from '@/assets/pictures/exam-library/all-exams.jpg'
import kmExamsImageUrl from '@/assets/pictures/exam-library/km-exams.png'
import questionBankImageUrl from '@/assets/pictures/exam-library/question-bank.jpg'
import ncmExamsImageUrl from '@/assets/pictures/exam-library/ncm-exams.png'
import diamondExamsImageUrl from '@/assets/pictures/exam-library/diamond-exams.png'
import attachmentUrl from '@/assets/pictures/exam-library/attachments.jpeg'
import { ExamsCategory } from '@/constants'
import { useFeatureFlagsStore } from '@/stores/featureFlags'

export function getExamLibraryCards(
  categories: ExamsCategory[]
): ExamLibraryCard[] {
  const t = i18n.global.t
  const featureFlags = useFeatureFlagsStore()

  const cards = getCardsList(t)

  const filteredCards = cards
    .filter((card) =>
      card.visible.some((flag) => featureFlags.hasFeatureFlag(flag))
    )
    .filter((card) => {
      return (
        categories.includes(card.category) ||
        card.category === ExamsCategory.OTHER
      )
    })

  return filteredCards
}

function getCardsList(t: (key: string) => string): ExamLibraryCard[] {
  return [
    {
      id: 1,
      title: t('libraryContent.colleague.title'),
      description: t('libraryContent.colleague.description'),
      url: 'colleague-exams',
      src: colleagueImageUrl,
      visible: [
        'FULL-EXAM-LIBRARY',
        'TEACHIQ-EXAM-LIBRARY',
        'MATHS-EXAM-LIBRARY',
      ],
      category: ExamsCategory.OTHER,
    },
    {
      id: 2,
      title: t('libraryContent.national.title'),
      description: t('libraryContent.national.description'),
      url: 'national-exams',
      src: nationalImageUrl,
      visible: [
        'FULL-EXAM-LIBRARY',
        'TEACHIQ-EXAM-LIBRARY',
        'MATHS-EXAM-LIBRARY',
      ],
      category: ExamsCategory.NATIONAL,
    },
    {
      id: 3,
      title: t('libraryContent.course.title'),
      description: t('libraryContent.course.description'),
      url: 'course-exams',
      src: courseImageUrl,
      visible: ['FULL-EXAM-LIBRARY'],
      category: ExamsCategory.COURSE,
    },
    {
      id: 4,
      title: t('libraryContent.kmExams.title'),
      description: t('libraryContent.kmExams.description'),
      url: 'teachiq-exams',
      src: kmExamsImageUrl,
      visible: [
        'FULL-EXAM-LIBRARY',
        'TEACHIQ-EXAM-LIBRARY',
        'MATHS-EXAM-LIBRARY',
      ],
      category: ExamsCategory.TEACHIQ,
    },
    {
      id: 5,
      title: t('libraryContent.all.title'),
      description: t('libraryContent.all.description'),
      url: 'all-exams',
      src: allExamsImageUrl,
      visible: [
        'FULL-EXAM-LIBRARY',
        'TEACHIQ-EXAM-LIBRARY',
        'MATHS-EXAM-LIBRARY',
      ],
      category: ExamsCategory.EVERYTHING,
    },
    {
      id: 6,
      title: t('libraryContent.diamond.title'),
      description: t('libraryContent.diamond.description'),
      url: 'diamond-exams',
      src: diamondExamsImageUrl,
      visible: ['FULL-EXAM-LIBRARY', 'TEACHIQ-EXAM-LIBRARY'],
      category: ExamsCategory.DIAMOND,
    },
    {
      id: 7,
      title: t('libraryContent.ncm.title'),
      description: t('libraryContent.ncm.description'),
      url: 'ncm-exams',
      src: ncmExamsImageUrl,
      visible: ['FULL-EXAM-LIBRARY', 'TEACHIQ-EXAM-LIBRARY'],
      category: ExamsCategory.NCM,
    },
    {
      id: 8,
      title: t('libraryContent.questionLibrary.title'),
      description: t('libraryContent.questionLibrary.description'),
      url: 'questions',
      src: questionBankImageUrl,
      visible: [
        'FULL-EXAM-LIBRARY',
        'MATHS-EXAM-LIBRARY',
        'TEACHIQ-EXAM-LIBRARY',
      ],
      category: ExamsCategory.OTHER,
    },
    {
      id: 9,
      title: t('libraryContent.attachments.title'),
      description: t('libraryContent.attachments.description'),
      url: 'attachments',
      src: attachmentUrl, // You may want to add a new image for attachments
      visible: [
        'FULL-EXAM-LIBRARY',
        'TEACHIQ-EXAM-LIBRARY',
        'MATHS-EXAM-LIBRARY',
      ],
      category: ExamsCategory.OTHER,
    },
  ]
}
