import colors from '@/components/theme/colors'

export const laraPreset = {
  primitive: {
    green: {
      50: colors.green50,
      100: colors.green100,
      200: colors.green200,
      300: colors.green300,
      400: colors.green400,
      500: colors.green500,
      600: colors.green600,
      700: colors.green700,
      800: colors.green800,
      900: colors.green900,
      950: colors.green950,
    },
    gray: {
      50: colors.gray50,
      100: colors.gray100,
      200: colors.gray200,
      300: colors.gray300,
      400: colors.gray400,
      500: colors.gray500,
      600: colors.gray600,
      700: colors.gray700,
      800: colors.gray800,
      900: colors.gray900,
      950: colors.gray950,
    },
    cyan: {
      50: colors.cyan50,
      100: colors.cyan100,
      200: colors.cyan200,
      300: colors.cyan300,
      400: colors.cyan400,
      500: colors.cyan500,
      600: colors.cyan600,
      700: colors.cyan700,
      800: colors.cyan800,
      900: colors.cyan900,
      950: colors.cyan950,
    },
    blue: {
      50: colors.blue50,
      100: colors.blue100,
      200: colors.blue200,
      300: colors.blue300,
      400: colors.blue400,
      500: colors.blue500,
      600: colors.blue600,
      700: colors.blue700,
      800: colors.blue800,
      900: colors.blue900,
      950: colors.blue950,
    },
    red: {
      50: colors.red50,
      100: colors.red100,
      200: colors.red200,
      300: colors.red300,
      400: colors.red400,
      500: colors.red500,
      600: colors.red600,
      700: colors.red700,
      800: colors.red800,
      900: colors.red900,
      950: colors.red950,
    },
    yellow: {
      50: colors.yellow50,
      100: colors.yellow100,
      200: colors.yellow200,
      300: colors.yellow300,
      400: colors.yellow400,
      500: colors.yellow500,
      600: colors.yellow600,
      700: colors.yellow700,
      800: colors.yellow800,
      900: colors.yellow900,
      950: colors.yellow950,
    },
    orange: {
      50: colors.orange50,
      100: colors.orange100,
      200: colors.orange200,
      300: colors.orange300,
      400: colors.orange400,
      500: colors.orange500,
      600: colors.orange600,
      700: colors.orange700,
      800: colors.orange800,
      900: colors.orange900,
      950: colors.orange950,
    },
  },
  semantic: {
    surface: {
      0: '#ffffff',
      50: '{gray.50}',
      100: '{gray.100}',
      200: '{gray.200}',
      300: '{gray.300}',
      400: '{gray.400}',
      500: '{gray.500}',
      600: '{gray.600}',
      700: '{gray.700}',
      800: '{gray.800}',
      900: '{gray.900}',
      950: '{gray.950}',
    },
    primary: {
      50: '{green.50}',
      100: '{green.100}',
      200: '{green.200}',
      300: '{green.300}',
      400: '{green.400}',
      500: '{green.500}',
      600: '{green.600}',
      700: '{green.700}',
      800: '{green.800}',
      900: '{green.900}',
      950: '{green.950}',
    },
    overlay: {
      modal: {
        borderRadius: 'var(--p-border-radius-md)',
      },
    },
    colorScheme: {
      light: {
        primary: {
          // by default
          color: '{primary.500}',
          contrastColor: '#ffffff',
          hoverColor: '{primary.600}',
          activeColor: '{primary.700}',
        },
        highlight: {
          // by default
          background: '{primary.50}',
          focusBackground: '{primary.100}',
          color: '{primary.700}',
          focusColor: '{primary.800}',
        },
        text: {
          color: '#373F41', // default: slate.700
        },
        formField: {
          disabledBackground: '{gray.50}', // default: surface.200
          color: '{text.color}', // default: slate.700
          floatLabelColor: '{gray.700}', // default: slate.500
          borderColor: '{gray.400}', // default: slate.300
          borderRadius: 'var(--p-border-radius-sm)', // default: var(--p-border-radius-md)
          paddingX: '10px', // default: 0.75rem
          paddingY: '10px', // default: 0.75rem
          placeholderColor: '{gray.700}', // default: slate.500
          disabledColor: '#141414', // default: slate.500
        },
      },
    },
  },
}
