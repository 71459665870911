import { KM } from './KM'
import { questionsChangedHash } from './KMExamFacade'
import { ExamAnswerArea, EXAM_TYPES, QuestionPointsMode } from '@/constants'
import { kmTranslate } from '@/i18n'
import { getExamPartSettingsHeading } from '@/utils/getDefaultExamPartsSettings'
import { generateDefaultLimitarr } from '../utils/generateLimitarr'
import { ExamConflictException } from '../../services/exceptions/ExamConflictException'

// Response from KM when save is denied
// {"stid":0,"uswaparr":[],"error":2,"errormsg":"OBS! Det g\u00e5r inte spara \u00f6ver det gamla provet eftersom det ligger sj\u00e4lv- eller l\u00e4rarbed\u00f6mningar p\u00e5 detta prov i systemet. Klicka p\u00e5 spara igen f\u00f6r att spara provet som en kopia."}

// Response from KM om successful save
// {"stid":"898981","uswaparr":[],"error":0,"errormsg":""}

// Form data POSTed to generics.php
/*
fm: [[3,0,0,0,0,0,3],[1,0,0,0,1,0,2],[1,0,0,0,0,0,1],[5,0,0,0,1,0,6]]
limitarr: {"connectformagor":{"b":0,"p":1,"pl":2,"m":2,"r":3,"k":3},"limits":[[[[2,0],[3,0],3],[[3,1],[3,1],1],[[3,1],[4,1],1]],[[[0,0],[0,0],0],[[0,0],[0,0],0],[[0,0],[0,0],0]],[[[0,0],[0,0],0],[[0,0],[0,0],0],[[0,0],[0,0],0]],[[[0,0],[0,0],0],[[0,1],[1,1],1],[[1,0],[1,0],0]],[[[2,0],[3,0],3],[[3,1],[4,2],2],[[4,1],[5,1],1]]]}
deltypes: [1,"1","7","10"]
t: 43010
schoolid: 2991
scid: 123439
repselclassid: 0
classname:
version: 11010577
estime: 10
testdate: 2021-12-07
uppgifter: 249881,249880,251823#264888#259066
classblock:
testname: Bråk med bråk - kopia
testtype: 2
sortedcontent: 1558|2053
courseid: 188
coursecode: ma1a
stid: 0
orgid: 777167
autosort: 1
epoints: 3
cpoints: 2
apoints: 1
digitalt: 1
showpointsnotmatrix: 0
ansoverridearr: {}
titleoverridearr: [[null,0],[null,0],[null,0],[null,0]]
globalbook: exp2a
coursetype: u
uppgiftclonechangearr: {}
litids: 0
aes: true
auto_marked: 0
addinfo: {"framesinside":0}
*/

export function getAbilityCodeList(course) {
  return course.formagor.map((f) => f[1])
}

/* INPUT:
   abilityCodeList = ['b', 'p', 'pl']
   questions = [v2question...] -> [[{data: {criterias: [{level: 'c', ability: 'p'}]}}]]

   OUTPUT:
   [
      // columns are abilities in order of abilityCodeList
      [3, 0, 0, 0, 0, 0, 3], // E
      [1, 0, 0, 0, 1, 0, 2], // C
      [1, 0, 0, 0, 0, 0, 1], // A
      [5, 0, 0, 0, 1, 0, 6], // Total
   ]
*/
export function getFormageMatris(abilityCodeList, questions) {
  const countsMap = questions
    .flatMap((q) => q.content)
    // Some information block sub questions are missing criterias
    .flatMap((sq) => sq.data?.criterias || [])
    .reduce((acc, rule) => {
      const key = `${rule.level}${rule.ability}`
      acc.set(key, (acc.get(key) || 0) + 1)
      const totalKey = `total${rule.ability}`
      acc.set(totalKey, (acc.get(totalKey) || 0) + 1)
      return acc
    }, new Map())

  const result = ['e', 'c', 'a', 'total'].map((levelKey) => {
    return abilityCodeList.map((abilityKey) => {
      const key = `${levelKey}${abilityKey}`
      return countsMap.get(key) || 0
    })
  })
  return result.map((row) => [...row, row.reduce((acc, cell) => acc + cell, 0)])
}

/*
 connectformagor: { b: 0, p: 1, pl: 2, m: 2, r: 3, k: 3 },
*/

export function getConnectedFormageMatris(connectFormagor, questions) {
  const numSlots =
    Object.values(connectFormagor).reduce(
      (max, slot) => Math.max(max, slot),
      0
    ) + 1
  const abilityConnectedToSlot = Object.keys(connectFormagor).reduce(
    (acc, abilityCode) => {
      acc.set(abilityCode, connectFormagor[abilityCode])
      return acc
    },
    new Map()
  )
  const countsMap = questions
    .flatMap((q) => q.content)
    .flatMap((sq) => sq.data?.criterias || [])
    .reduce((acc, rule) => {
      const slotKey = abilityConnectedToSlot.get(rule.ability)
      const key = `${rule.level}${slotKey}`
      acc.set(key, (acc.get(key) || 0) + 1)
      const totalKey = `total${slotKey}`
      acc.set(totalKey, (acc.get(totalKey) || 0) + 1)
      const totalRowKey = `${rule.level}total`
      acc.set(totalRowKey, (acc.get(totalRowKey) || 0) + 1)
      const totalTotalKey = `totaltotal`
      acc.set(totalTotalKey, (acc.get(totalTotalKey) || 0) + 1)
      return acc
    }, new Map())

  const result = ['e', 'c', 'a', 'total'].map((levelKey) => {
    return [...Array(numSlots).keys(), 'total'].map((slotKey) => {
      const key = `${levelKey}${slotKey}`
      return countsMap.get(key) || 0
    })
  })
  return result
}
/*
limitarr = {
      connectformagor: { b: 0, p: 1, pl: 2, m: 2, r: 3, k: 3 },
      limits: [
        [
          [[2, 0], [3, 0], 3],
          [[3, 1], [3, 1], 1],
          [[3, 1], [4, 1], 1],
        ],
        [
          [[0, 0], [0, 0], 0],
          [[0, 0], [0, 0], 0],
          [[0, 0], [0, 0], 0],
        ],
        [
          [[0, 0], [0, 0], 0],
          [[0, 0], [0, 0], 0],
          [[0, 0], [0, 0], 0],
        ],
        [
          [[0, 0], [0, 0], 0],
          [[0, 1], [1, 1], 1],
          [[1, 0], [1, 0], 0],
        ],
        [
          [[2, 0], [3, 0], 3],
          [[3, 1], [4, 2], 2],
          [[4, 1], [5, 1], 1],
        ],
      ],
    }
    */

const delTypes = [
  /* No longer use kortsvar in KM
  { id: 1, kortsvar: true, calculator: false, autocorr: true },
  { id: 2, kortsvar: true, calculator: true, autocorr: true },
  { id: 3, kortsvar: true, calculator: false, digital: true },
  { id: 4, kortsvar: true, calculator: true, digital: true },
  { id: 5, kortsvar: true, calculator: false, digital: false },
  { id: 6, kortsvar: true, calculator: true, digital: false }, */
  { id: 7, kortsvar: false, calculator: false, digital: true },
  { id: 8, kortsvar: false, calculator: true, digital: true },
  { id: 9, kortsvar: false, calculator: false, digital: false },
  { id: 10, kortsvar: false, calculator: true, digital: false },
]

export function mapToDelTypes(courseSettings, parts) {
  const calculatorApplicableInCourse = courseSettings.calcred
  return [calculatorApplicableInCourse].concat(
    parts
      .map((p) => {
        const partCalculator = !!p.config.calculatorAllowed
        const partDigital = p.config.answerArea === ExamAnswerArea.INLINE
        const delType = delTypes.find((dt) => {
          if (dt.calculator !== partCalculator) {
            return false
          }
          if (dt.digital !== partDigital) {
            return false
          }
          return true
        })
        if (!delType) {
          throw new Error(
            `Could not find delType for part with ${partCalculator}, ${partDigital}`
          )
        }
        return delType.id
      })
      // IDs as strings
      .map((id) => '' + id)
  )
}

function mapQuestionsToUppgifter(parts) {
  return parts.map((p) => p.questions.map((q) => q.id).join(',')).join('#')
}

// OUTPUT: '1558 | 2053'
function mapSortedContent(parts) {
  const content = parts
    .flatMap((p) => p.questions)
    .filter((q) => q.context?.chapter?.id)
    .map((q) => q.context.chapter.id)
  const uniqueContent = [...new Set(content)].filter((id) => id)
  return uniqueContent.join('|')
}

function areAllQuestionsAutoCorrect(parts) {
  return !parts
    .flatMap((p) => p.questions)
    .find((q) => q.context.autocorrect === false)
}

/*
teacherInfo = {
  teacher: {
    id: 1,
    version: 123123213
  },
  school: {
    id: 1,
    orgId: 1,
    aes: true

  }
}
klass = {
  id: classId,
  name: className
}
*/

function mapQuestionsToLiteratureIds(parts) {
  return parts
    .flatMap((p) => p.questions)
    .flatMap((q) => q.attachments)
    .map((r) => r.id)
}

function mapPartTitles(exam, parts, courseSettings) {
  const calculatorApplicableInCourse = courseSettings.calcred
  const autoGenerateTag = "<span id='auto' style='display: hidden'/>"
  const coverSheetValue = [null, 0, exam.settings.coverSheet]
  return [coverSheetValue].concat(
    parts.map((p, partIndex) => {
      let heading = p.config.heading
      if (heading === '') {
        // Ensure KM hides empty headings
        heading = `<div>&nbsp;</div>`
      } else if (!heading && p.questions.length > 0) {
        heading = `${getExamPartSettingsHeading(
          true,
          kmTranslate,
          exam.parts.length,
          partIndex,
          p.config,
          calculatorApplicableInCourse
        )} ${autoGenerateTag}`
      } else if (!heading) {
        heading = null
      }
      return [heading, 0]
    })
  )
}

function questionsUpdated(exam) {
  const edited = exam.parts.flatMap((p) => p.questionsEdited || [])
  if (edited.length > 0) {
    return true
  }
  return questionsChangedHash(exam.parts) !== exam.metadata?.hash
}

function mapPointSetting(pointsMode) {
  switch (pointsMode) {
    case QuestionPointsMode.POINTS_DIFFICULTY:
      return 0
    case QuestionPointsMode.POINTS_TOTAL:
      return 1
    default:
      return 1
  }
}

function makeSureExamHasThreeParts(exam) {
  const parts = JSON.parse(JSON.stringify(exam.parts))
  while (parts.length < 3) {
    const answerArea = ExamAnswerArea.INLINE
    const calculatorAllowed = false
    parts.push({
      questions: [],
      config: {
        calculatorAllowed,
        answerArea,
      },
    })
  }
  return parts
}

function mapCurrentDisp(question) {
  /* clonedisp is used within KM to identify and reuse partial clones of questions
   0 = informationBlock or deleted subquestion
   1 = subquestion */
  const clonedisp = [...(question.metadata.subQuestionFingerprint || [])]
  // KM uses strings of length 7 and refuses to edit questions with more than 7 subquestions
  while (clonedisp.length < 7) {
    clonedisp.push(false)
  }
  return clonedisp.map((b) => (b ? '1' : '0')).join('')
}

function mapCurrentPointArr(question, abilityCodes) {
  const currentpointarr = { e: 0, c: 0, a: 0, pointcomp: [] }
  const levels = ['e', 'c', 'a']
  levels.forEach((level) => {
    for (let i = 0; i <= 12; i++) {
      currentpointarr[`${level}${i}`] = 0
    }
  })
  question.content.forEach((subquestion) => {
    if (subquestion.isQuestion) {
      const subquestionpoints = { e: 0, c: 0, a: 0 }
      subquestion.data.criterias.forEach((criteria) => {
        const thispoint = criteria.level + criteria.ability
        const abilityIndex = abilityCodes.indexOf(criteria.ability)
        const levelAbilityKey = `${criteria.level}${abilityIndex}`
        currentpointarr[levelAbilityKey] = currentpointarr[levelAbilityKey] || 0
        currentpointarr[levelAbilityKey]++
        currentpointarr[criteria.level] = currentpointarr[criteria.level] || 0
        currentpointarr[criteria.level]++
        subquestionpoints[criteria.level]++
        currentpointarr.pointcomp.push(thispoint)
      })
    }
  })
  currentpointarr.pointcomp = currentpointarr.pointcomp.join('+')
  return currentpointarr
}

function mapCloneChangeArr(exam, course) {
  const abilityCodes = getAbilityCodeList(course)
  const questions = exam.parts.flatMap((part) =>
    part.questions.filter(
      (q) => part.questionsEdited && part.questionsEdited[q.id]
    )
  )
  const clonechangearr = questions.reduce((acc, q) => {
    acc[q.id] = {
      cloneof: q.id,
      currentgiant: q.content,
      currentdisp: mapCurrentDisp(q),
      currentpointarr: mapCurrentPointArr(q, abilityCodes),
    }
    return acc
  }, {})
  return clonechangearr
}

function mapAnswerOverrides(exam) {
  if (!exam.settings.answerOverrides) {
    return {}
  }

  const allQuestions = exam.parts
    .flatMap((p) => p.questions)
    .reduce((acc, q) => {
      acc[q.id] = q
      return acc
    }, {})

  return Object.keys(exam.settings.answerOverrides).reduce((acc, id) => {
    const question = allQuestions[id]
    const questionOverrides = exam.settings.answerOverrides[id]
    const kmQuestionOverrides = ['0', '0', '0', '0', '0', '0', '0']
    Object.keys(questionOverrides).forEach((subQuestionId) => {
      const subQuestionIndex = question.content.findIndex(
        (sq) => String(sq.id) === String(subQuestionId)
      )
      if (subQuestionIndex === -1 || subQuestionIndex >= 7) {
        return
      }
      kmQuestionOverrides[subQuestionIndex] = String(
        questionOverrides[subQuestionId]
      )
    })
    acc[id] = kmQuestionOverrides
    return acc
  }, {})
}

function mapToKMExam(exam, course, teacherInfo, copiedFrom = undefined) {
  const parts = makeSureExamHasThreeParts(exam)
  const courseSettings = course.coursesettings
  const allQuestions = exam.parts.flatMap((p) => p.questions)
  const fm = getFormageMatris(getAbilityCodeList(course), allQuestions)
  const connectFormagor = course.connectformagor
  const connectedFormageMatris = getConnectedFormageMatris(
    connectFormagor,
    allQuestions
  )
  // Only generate new if questions have changed
  const limitarr =
    questionsUpdated(exam) ||
    !exam.metadata?.limitarr ||
    exam.settings?.defaultLimits
      ? generateDefaultLimitarr(
          course.subjectid,
          connectFormagor,
          connectedFormageMatris
        )
      : exam.metadata?.limitarr
  const delTypes = mapToDelTypes(courseSettings, parts)
  const uppgifter = mapQuestionsToUppgifter(parts)
  const sortedcontent = mapSortedContent(parts)
  const literatureIds = mapQuestionsToLiteratureIds(parts)
  const examTitles = mapPartTitles(exam, parts, courseSettings)
  const clonechangearr = mapCloneChangeArr(exam, course)
  const ansoverridearr = mapAnswerOverrides(exam)

  const params = {
    // Förmågematris
    fm: JSON.stringify(fm),
    limitarr: JSON.stringify(limitarr),
    // Types of each part (see delTypes.js)
    deltypes: JSON.stringify(delTypes),
    // used to generate versioncode, ???
    t: teacherInfo.teacher.id,
    schoolid: teacherInfo.school.id,
    // class ID
    scid: exam.group?.id || 0,
    repselclassid: 0,
    classname: exam.group?.name || '',
    version: teacherInfo.teacher.version,
    // estimated time
    estime: exam.settings.basic.duration || '',
    // FIXME hardcoded test date
    testdate: exam.examinationAt || '',
    uppgifter,
    classblock: exam.blockStudentAccess.join(','),
    testname: exam.name,
    testtype: EXAM_TYPES.get(exam.type).kmId,
    // List of all central content used in the exam
    sortedcontent,
    courseid: course.courseid,
    coursecode: course.coursecode,
    stid: exam.id || 0,
    orgid: copiedFrom || exam.metadata?.orgid || 0,
    // Pending project discussion re autosort
    autosort: 0,
    epoints: fm[0].slice(0, -1).reduce((s, p) => s + p, 0),
    cpoints: fm[1].slice(0, -1).reduce((s, p) => s + p, 0),
    apoints: fm[2].slice(0, -1).reduce((s, p) => s + p, 0),
    // Not used
    digitalt: 1,
    showpointsnotmatrix: mapPointSetting(exam.settings.basic.pointsMode),
    ansoverridearr: JSON.stringify(ansoverridearr),
    titleoverridearr: JSON.stringify(examTitles),
    globalbook: exam.material.id.split(':')[1],
    coursetype: course.coursetype,
    uppgiftclonechangearr: JSON.stringify(clonechangearr),
    litids: literatureIds.join(',') || 0,
    aes: teacherInfo.school.aes,
    auto_marked: areAllQuestionsAutoCorrect(parts) ? '1' : '0',
    addinfo: JSON.stringify({ framesinside: 0 }),
  }
  return params
}

export async function saveKMExam(
  exam,
  course,
  teacherInfo,
  copiedFrom = undefined
) {
  const params = mapToKMExam(exam, course, teacherInfo, copiedFrom)
  const response = await KM.saveTest(params)

  if (response.data.error === 2) {
    throw new ExamConflictException()
  }
  const swappedQuestions = (response.data.uswaparr || []).map((swap) => {
    return {
      from: swap[0],
      to: swap[1],
      clone: swap[2],
    }
  })
  return {
    examId: response.data.stid,
    examcode: response.data.examcode,
    swappedQuestions,
  }
}

export async function mapKMExamForPrint(exam, course, teacherInfo) {
  const questions = await getQuestionsForPrinting(exam, course)

  const examForPrint = mapToKMExam(exam, course, teacherInfo)

  examForPrint.uppgiftcontent = questions
  examForPrint.kurs = course.name
  examForPrint.apoints = String(examForPrint.apoints)
  examForPrint.cpoints = String(examForPrint.cpoints)
  examForPrint.epoints = String(examForPrint.epoints)
  examForPrint.autosort = String(examForPrint.autosort)
  examForPrint.auto_marked = String(examForPrint.auto_marked ? '1' : '0')
  examForPrint.digitalt = String(examForPrint.digitalt)
  examForPrint.teacherid = String(examForPrint.teacherid)
  examForPrint.testtype = String(examForPrint.testtype)
  examForPrint.teacherid = String(examForPrint.t)
  examForPrint.namn = examForPrint.name
  examForPrint.name = examForPrint.testname
  delete examForPrint.addinfo
  return examForPrint
}

async function getQuestionsForPrinting(exam, course) {
  const abilityCodes = getAbilityCodeList(course)
  return exam.parts.flatMap((p, index) =>
    p.questions.map((q) => {
      const pointarr = mapCurrentPointArr(q, abilityCodes)
      delete pointarr.pointcomp
      return {
        id: q.id,
        kortsvar: q.settings.shortFormQuestion ? '1' : '0',
        ks: q.settings.shortFormQuestion ? '1' : '0',
        autocorr: q.context.autocorrect,
        chosen: Number(index + 1),
        htmlarr: JSON.stringify(q.content),
        version: q.id,
        epoints: pointarr.e,
        cpoints: pointarr.c,
        apoints: pointarr.a,
        litids: q.attachments.map((r) => r.id).join(','),
        ...pointarr,
      }
    })
  )
}
