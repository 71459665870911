import { bookGetById, bookSortingGetById } from '@/services/book'
import { examsCheckQuestions } from '@/services/exams.js'
import { courseGetById, courseSettingsGetById } from '@/services/course.js'
import {
  abilitiesGetByNodeId,
  curriculumGetById,
  getMyCurricula as serviceGetMyCurricula,
} from '@/services/curriculum'
import {
  questionGetById,
  questionGetDynamicContent,
  // questionSolution, // TODO : Implement this
  questionGetByVersionId,
  questionSearchByNodeId,
  questionReport,
  questionUsedInExams,
} from '@/services/question'
import { attachmentsGet } from '@/services/attachment'
import {
  settingsGetMaterialPickerSetting,
  settingsSetMaterialPickerSetting,
  settingsGetRecentlyPicked,
  settingsSetRecentlyPicked,
  settingsGetRecentSubject,
  settingsSetRecentSubject,
} from '@/services/settings'
import { gradeThresholdsGetbyCode } from '@/services/gradeThresholds'
import { groupsGetBySubjectId } from '@/services/group'
import { Difficulty, QuestionPointsMode } from '@/constants'
import { calculateExamLimits } from 'gauss-matrix'
import { useTokenStore } from '@/stores/token'
import { sendFeedback as _sendFeedback } from '@/services/feedback'
import ExamService from '@/services/ExamService'
import { useI18n } from 'vue-i18n'
import { useFeatureFlagsStore } from '@/stores/featureFlags'

function mergeDynamicContent(question, dynamicContent) {
  question.context.usedIn = dynamicContent.context.usedIn
  question.context.usedInExams = dynamicContent.context.usedInExams
  question.solutions = dynamicContent.solutions
  question.attachments = dynamicContent.attachments
  return question
}

async function getExamById(id, anonymous = false) {
  return await ExamService.getExamById(id, anonymous)
}

async function checkExamQuestions(courseId, questions) {
  return await examsCheckQuestions(courseId, questions)
}

async function saveExam(exam) {
  return await ExamService.saveExam(exam)
}

async function createExam(exam, copiedFrom) {
  return await ExamService.createExam(exam, copiedFrom)
}

async function getAbilities(nodeId) {
  const abilityList = Object.entries(await abilitiesGetByNodeId(nodeId)).map(
    (a) => ({ id: a[0], name: a[1].name, group: a[1].group })
  )
  return abilityList
}

async function getConnectedAbilities(nodeId) {
  const abilities = await getAbilities(nodeId)
  return abilities.reduce((obj, a) => {
    obj[a.id] = a.group
    return obj
  }, {})
}

async function getAbilityMapForNodeId(nodeId) {
  const abilities = await getAbilities(nodeId)
  return abilities.reduce((obj, item) => {
    return {
      ...obj,
      [item.id]: item.name,
    }
  }, {})
}

async function getQuestion(id, versionId, bookId) {
  const abilityMap = await getAbilityMapForNodeId(bookId)
  let question
  // Temporary fix to load content from the dynamic endpoint
  if (versionId) {
    const [q, d] = await Promise.all([
      questionGetById(id, versionId),
      questionGetDynamicContent(versionId),
    ])
    mergeDynamicContent(q, d)
    question = q
  } else {
    question = await questionGetById(id, versionId)
    const d = await questionGetDynamicContent(question.questionVersionId)
    mergeDynamicContent(question, d)
  }

  return ExamService.mapQuestion(question, abilityMap, bookId)
}

async function getQuestionByPrimaryId(id, bookId) {
  return await getQuestion(null, id, bookId)
}

async function refreshQuestionCriterias(_materialId, _q) {
  throw new Error('Not implemented')
}

async function uploadSolution(_solution, _questionId) {
  // TODO : Implement this
  // const type = solution.type === 1 ? 'image' : 'youtube'
  // const src = solution.url

  // return await questionSolution(questionId, type, src)
  throw new Error('Not implemented')
}

async function getQuestionByVersionId(versionId, bookId, abilityMap) {
  if (!abilityMap) {
    abilityMap = await getAbilityMapForNodeId(bookId)
  }
  const [q, d] = await Promise.all([
    questionGetByVersionId(versionId),
    questionGetDynamicContent(versionId),
  ])
  mergeDynamicContent(q, d)

  return ExamService.mapQuestion(q, abilityMap, bookId)
}

export async function getHierarchyFromMaterial(materialId) {
  const book = await bookGetById(materialId)

  const buildBookHierarchy = (book) => {
    return {
      id: book.id,
      type: 'BOOK',
      name: book.name,
      chapters: book.chapters.map((chapter) => ({
        id: chapter.id,
        name: chapter.name,
        subchapters: chapter.subchapters.map((subchapter) => {
          return {
            id: subchapter.id,
            name: subchapter.name,
          }
        }),
      })),
    }
  }

  const returnValue =
    book.type !== 'BOOKSERIES'
      ? buildBookHierarchy(book)
      : {
          id: book.id,
          name: book.name,
          type: book.type,
          books: book.books.map((b) => buildBookHierarchy(b)),
        }

  return returnValue
}

async function promiseQuestions(bookId, nodes, params) {
  const nodeIds = nodes.map((n) => n.id)
  const results = await questionSearchByNodeId(
    nodeIds.length > 0 ? nodeIds : [bookId],
    params
  )
  const questionIds = results.questions.map((q) => ({
    id: parseInt(q.id),
    questionVersionId: parseInt(q.questionVersionId),
  }))
  const abilityMap = await getAbilityMapForNodeId(bookId)
  return {
    facets: results.facets || [],
    totalCount: results.metadata.count,
    promises: questionIds.map((q, index) => {
      const promiseObject = {}
      promiseObject.id = q.id
      promiseObject.key = q.questionVersionId
      promiseObject.questionVersionId = q.questionVersionId
      promiseObject.promiseRetriever = () => {
        if (!promiseObject.promise) {
          promiseObject.promise = getQuestionByVersionId(
            q.questionVersionId,
            bookId,
            abilityMap
          ).catch((e) => {
            promiseObject.error = e
            return null
          })
        }
        return promiseObject.promise
      }
      if (index <= 5) {
        promiseObject.promiseRetriever()
      }
      return promiseObject
    }),
  }
}

async function reportQuestion(
  _questionId,
  versionId,
  _courseId,
  { type, comment }
) {
  return await questionReport(versionId, { type, comment })
}

function getPointTypes() {
  return [
    { key: 'e', name: Difficulty.EASY },
    { key: 'c', name: Difficulty.MEDIUM },
    { key: 'a', name: Difficulty.DIFFICULT },
  ]
}
async function getMyCurricula() {
  return await serviceGetMyCurricula()
}

async function getCurriculumById(id) {
  return await curriculumGetById(id)
}

// TODO: shared classes is not implemented in gauss yet
async function getClassesAndSharedClasses(_userId, subjectId) {
  const classes = await groupsGetBySubjectId(subjectId)
  return classes
}

async function getCourse(courseId) {
  const course = await courseGetById(courseId)
  const settings = await getCourseSettings(courseId)
  const abilities = await getAbilities(courseId)
  return {
    id: course.id,
    name: course.name,
    type: course.type,
    subjectId: course.subjectId,
    curriculumId: course.curriculumId,
    language: course.language,
    settings,
    abilities,
  }
}

async function getCourseSettings(courseId) {
  const settings = await courseSettingsGetById(courseId)

  return {
    calculator: settings.CALCULATOR === '1',
    gradeThreshold: settings.GRADE_THRESHOLD || 'default',
    pointsMode: QuestionPointsMode.POINTS_TOTAL,
    displayCriteriasAsMatrixThreshold: 999,
    skipBlankAbilityRows: false,
  }
}

async function getMaterialPickerSettings() {
  return await settingsGetMaterialPickerSetting()
}

async function setMaterialPickerSettings(courseId, materialId) {
  await settingsSetMaterialPickerSetting(courseId, materialId)
}

async function getRecentlyPickedMaterial() {
  return await settingsGetRecentlyPicked()
}

async function setRecentlyPickedMaterial(materials) {
  await settingsSetRecentlyPicked(materials)
}

async function getRecentSubject() {
  return await settingsGetRecentSubject()
}

async function setRecentSubject(curriculumId, subjectId) {
  return await settingsSetRecentSubject(curriculumId, subjectId)
}

const bookSorting = async (bookId) => {
  const sorting = await bookSortingGetById(bookId)
  return sorting
}

const mapAttachmentToHtml = (attachment) => {
  if (attachment.contentType === 'audio') {
    return (
      `<audio controls src="${attachment.content.url}"></audio>` +
      attachment.content.text
    )
  }
  if (attachment.contentType === 'link') {
    return attachment.content.url
  }
  return attachment.content.html
}

async function getAttachmentsContent(attachments) {
  const attachmentIds = attachments.map((attachment) => attachment.id)
  return (await attachmentsGet(attachmentIds)).map((attachment) => ({
    id: attachment.id,
    title: attachment.title,
    author: attachment.author,
    type: attachment.contentType,
    // FIXME ideally we should refactor html in here and in
    // KMExamFacade so both provide something closer to KM 2.0 backend
    html: mapAttachmentToHtml(attachment),
  }))
}

async function sendFeedback(rating, comment) {
  return await _sendFeedback({
    rating: Number(rating),
    comment,
  })
}

function getQuestionEditorUrl(
  questionId = 0,
  materialId = '',
  forceNew = true,
  attachmentId = ''
) {
  const tokenStore = useTokenStore()
  const i18n = useI18n()
  const featureFlags = useFeatureFlagsStore()
  let url = `/qe/?token=${tokenStore.token}&locale=${i18n.locale?.value}&eca=${featureFlags.hasFeatureFlag('ECA')}`
  if (questionId !== 0) {
    url += `&questionId=${questionId}`
  }
  if (forceNew) {
    url += '&new=1'
  }
  if (materialId !== '' && materialId !== '0') {
    url += '&materialId=' + materialId
  }
  if (attachmentId !== '') {
    url += `&attachment=${attachmentId}`
  }
  return url
}

async function getDefaultLimits(exam) {
  const connectedAbilities = await getConnectedAbilities(exam.course.id)
  const gradeThresholdCode = exam.course.settings.gradeThreshold

  const { algorithm, thresholds } =
    await gradeThresholdsGetbyCode(gradeThresholdCode)

  const limits = calculateExamLimits(
    algorithm,
    thresholds,
    connectedAbilities,
    exam.parts.flatMap((part) => part.questions).flatMap((q) => q.criterias)
  )

  return { limits, connectedAbilities }
}

async function getQuestionForEditor(_questionId, _content) {
  throw new Error('Not implemented')
}

async function isQuestionUsedInExams(questionId) {
  return await questionUsedInExams(questionId).usedInExams
}

async function getPreferredBook(_courseId, _classId) {
  return undefined
}

async function hierachySummaryEnabledForCourse(_courseId) {
  return true
}

export default {
  getExamById,
  // getMyCourses,
  getCourse,
  getMyCurricula,
  getCurriculumById,
  getClassesAndSharedClasses,
  // getMyBooksByCourseId,
  getCourseSettings,
  checkExamQuestions,
  saveExam,
  createExam,
  getQuestion,
  getQuestionByPrimaryId,
  refreshQuestionCriterias,
  // getQuestionByVersionId,
  // findQuestions,
  promiseQuestions,
  reportQuestion,
  getHierarchyFromMaterial,
  getAbilities,
  getAttachmentsContent,
  getPointTypes,
  // getBooksInSeries,
  getMaterialPickerSettings,
  setMaterialPickerSettings,
  getRecentlyPickedMaterial,
  setRecentlyPickedMaterial,
  getRecentSubject,
  setRecentSubject,
  bookSorting,
  sendFeedback,
  uploadSolution,
  getQuestionEditorUrl,
  getDefaultLimits,
  getQuestionForEditor,
  isQuestionUsedInExams,
  getPreferredBook,
  hierachySummaryEnabledForCourse,
}
