import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import {
  PageSize,
  FIRST_PAGE_INDEX,
  SORT_BY_RELEVANCE_KEY,
  SearchVisibility,
  BinaryOption,
} from '@/constants'
import {
  groupPracticeHierarchyGet,
  groupPracticeQuestionBlock,
  groupPracticeQuestionUnblock,
  groupPracticeSubchapterBlock,
  groupPracticeSubchapterUnblock,
} from '@/services/group'
import { useGroupStore } from '@/stores/group'

type HierarchySubchapter = {
  id: number
  name: string
  blockedQuestions: number[]
  openInPractice: boolean
  type: 'SUBCHAPTER'
}

type HierarchyChapter = {
  id: number
  name: string
  type: 'CHAPTER'
  openInPractice: boolean
  subchapters: Record<number, HierarchySubchapter>
}

type MappedHierarchy = Record<number, HierarchyChapter>

type HierarchyFromMaterialAPI = {
  id: number
  name: string
  type: 'BOOK' | 'BOOKSERIES'
  subject: {
    id: number
    name: string
  }
  course: {
    id: number
    name: string
  }
  curriculum: {
    id: number
    name: string
  }
  chapters: {
    id: number
    name: string
    type: 'CHAPTER'
    subchapters: {
      id: number
      name: string
      blockedQuestions: number[]
      openInPractice: boolean
      type: 'SUBCHAPTER'
    }[]
  }[]
  books: {
    id: number
    name: string
    type: 'BOOK'
    chapters: {
      id: number
      name: string
      type: 'CHAPTER'
      subchapters: {
        id: number
        name: string
        blockedQuestions: number[]
        openInPractice: boolean
        type: 'SUBCHAPTER'
      }[]
    }[]
  }[]
}

const BLANK_HIERARCHY: HierarchyFromMaterialAPI = {
  id: 0,
  name: '',
  type: 'BOOK',
  subject: {
    id: 0,
    name: '',
  },
  course: {
    id: 0,
    name: '',
  },
  curriculum: {
    id: 0,
    name: '',
  },
  chapters: [],
  books: [],
}

type FacetItem = {
  count: number
  value: string
}

type Facets = {
  types: FacetItem[]
}

type QueryParams = {
  sortBy: string
  query: string
  autocorrect: boolean
  abilities: string[]
  pointTypes: string[]
  types: string[]
  visibility: string
  practiceQuestion: boolean
  page: number
  pageSize: number
}

type QuestionFilters = {
  sortBy: string
  query: string
  autocorrect: string
  abilities: string[]
  pointTypes: string[]
  type: string
  visibility: string
}

type Store = {
  seriesBookId: number | null
  contentIds: Record<string, boolean> | null
  contentLabels: string[]
  params: QuestionFilters
  facets: Facets | null
  page: number
  pageSize: number
}

const IGNORED_PARAMS = [
  'query',
  'sortBy',
  'page',
  'pageSize',
  'visibility',
  'practiceQuestion',
]

const BLANK_PARAMS = {
  sortBy: SORT_BY_RELEVANCE_KEY,
  query: '',
  autocorrect: '',
  abilities: [],
  pointTypes: [],
  type: '', // FIXME: Rename to questionType
  visibility: SearchVisibility.PRACTICE,
  practiceQuestion: true,
}

const initialStore = (): Store => ({
  seriesBookId: null, // used for book in bookseries
  contentIds: null,
  contentLabels: [],
  params: JSON.parse(JSON.stringify(BLANK_PARAMS)),
  facets: null,
  page: FIRST_PAGE_INDEX,
  pageSize: PageSize.NORMAL,
})

export const usePracticeStore = defineStore('practice', {
  state: () => ({
    store: useSessionStorage('practiceSearch', initialStore()),
    hierarchy: BLANK_HIERARCHY,
    mappedHierarchy: {} as MappedHierarchy,
    blockedQuestions: [] as number[],
  }),
  getters: {
    search: (state) => {
      if (!state.store) {
        state.store = initialStore()
      }
      return state.store
    },
    queryParams(): Partial<QueryParams> {
      return {
        sortBy: this.search.params.sortBy,
        ...(this.search.params.query && {
          query: this.search.params.query,
        }),
        ...(this.search.params.autocorrect && {
          autocorrect: this.search.params.autocorrect === BinaryOption.YES,
        }),
        ...(this.search.params.abilities.length > 0 && {
          abilities: this.search.params.abilities,
        }),
        ...(this.search.params.pointTypes.length > 0 && {
          pointTypes: this.search.params.pointTypes,
        }),
        ...(this.search.params.type && {
          type: this.search.params.type,
        }),
        visibility: SearchVisibility.ALL,
        practiceQuestion: true,
        page: this.search.page,
        pageSize: this.search.pageSize,
      }
    },
    numberOfQueryParams(): number {
      const numFilter = Object.entries(this.search.params).reduce(
        (acc: number, [key, value]) => {
          if (
            !IGNORED_PARAMS.includes(key) &&
            (Array.isArray(value) ? value.length > 0 : value)
          ) {
            return acc + 1
          }
          return acc
        },
        0
      )
      return numFilter
    },
    nodeList(): object[] {
      const nodeList: object[] = []
      let hasRepetition = false
      Object.keys(this.search.contentIds || {}).forEach((selectedKey) => {
        const [chapterKey, subchapterKey] = selectedKey.split('_')
        if (subchapterKey) {
          if (chapterKey === 'repetition') {
            hasRepetition = true
            nodeList.push({ id: Number(subchapterKey), type: 'repetition' })
          } else {
            nodeList.push({ id: Number(subchapterKey), type: 'subchapter' })
          }
        } else {
          if (chapterKey === 'repetition') {
            nodeList.push({ id: 0, type: 'allRepetition' })
          } else {
            nodeList.push({ id: Number(chapterKey), type: 'chapter' })
          }
        }
      })
      if (hasRepetition) {
        // Repetition can not be mixed with other chapters or subchapters
        return nodeList.filter((node: any) => node.type === 'repetition')
      }
      return nodeList
    },
    expandedChapters(): Record<number, boolean> {
      return Object.keys(this.search.contentIds || {}).reduce(
        (acc, key) => {
          const chapterId = key.split('_')[0]
          acc[Number(chapterId)] = true
          return acc
        },
        {} as Record<number, boolean>
      )
    },
  },
  actions: {
    async getHierarchy() {
      const groupStore = useGroupStore()
      const groupId = groupStore.group.id

      if (!groupId) {
        throw new Error('No group loaded')
      }

      const bookId =
        groupStore.group.settings.books[0]?.bookSeriesId ||
        groupStore.group.settings.books[0]?.bookId

      if (!bookId) {
        throw new Error('No book loaded')
      }

      const retrievedHierarchy = await groupPracticeHierarchyGet(
        groupId,
        bookId
      )
      return retrievedHierarchy
    },
    async loadHierarchy() {
      this.hierarchy = await this.getHierarchy()
      const groupStore = useGroupStore()

      if (
        this.hierarchy.type === 'BOOKSERIES' &&
        groupStore.group.settings.books[0].bookSeriesId
      ) {
        this.store.seriesBookId = groupStore.group.settings.books[0].bookId
      }

      this.mappedHierarchy = this.mapHierarchy(this.hierarchy)
      this.blockedQuestions = this.extractBlockedQuestions(this.hierarchy)
    },
    mapHierarchy(hierarchy: HierarchyFromMaterialAPI): MappedHierarchy {
      let chapters = hierarchy.chapters

      if (hierarchy.type === 'BOOKSERIES') {
        chapters =
          hierarchy.books.filter(
            (book) => book.id === this.store.seriesBookId
          )[0]?.chapters || []
      }

      return chapters.reduce(
        (acc: Record<number, HierarchyChapter>, chapter) => {
          acc[chapter.id] = {
            id: chapter.id,
            name: chapter.name,
            type: chapter.type,
            openInPractice: chapter.subchapters.some(
              (subchapter) => subchapter.openInPractice
            ),
            subchapters: chapter.subchapters.reduce(
              (acc: Record<number, HierarchySubchapter>, subchapter) => {
                acc[subchapter.id] = {
                  id: subchapter.id,
                  name: subchapter.name,
                  type: subchapter.type,
                  blockedQuestions: subchapter.blockedQuestions,
                  openInPractice: subchapter.openInPractice,
                }
                return acc
              },
              {}
            ),
          }
          return acc
        },
        {}
      )
    },
    extractBlockedQuestions(hierarchy: HierarchyFromMaterialAPI): number[] {
      let chapters = hierarchy.chapters

      if (hierarchy.type === 'BOOKSERIES') {
        chapters =
          hierarchy.books.filter(
            (book) => book.id === this.store.seriesBookId
          )[0]?.chapters || []
      }

      return chapters.reduce((acc: number[], chapter) => {
        chapter.subchapters.forEach((subchapter) => {
          acc.push(...subchapter.blockedQuestions)
        })
        return acc
      }, [])
    },
    async toggleBlockQuestion(questionId: number) {
      const groupStore = useGroupStore()
      const groupId = groupStore.group.id

      if (!groupId) {
        throw new Error('No group loaded')
      }

      if (this.isQuestionBlocked(questionId)) {
        await groupPracticeQuestionUnblock(groupId, questionId)
        this.blockedQuestions = this.blockedQuestions.filter(
          (id) => id !== questionId
        )
      } else {
        await groupPracticeQuestionBlock(groupId, questionId)
        this.blockedQuestions.push(questionId)
      }
    },
    isQuestionBlocked(questionId: number) {
      return this.blockedQuestions.includes(questionId)
    },
    isQuestionOpenForPractice(questionId: number, usedIn: UsedInLocation[]) {
      return (
        this.isHierarchyOpenForPractice(usedIn) &&
        !this.isQuestionBlocked(questionId)
      )
    },
    isChapterOpenForPractice(chapterId: number) {
      return this.mappedHierarchy[chapterId].openInPractice
    },
    isSubchapterOpenForPractice(chapterId: number, subchapterId: number) {
      return this.mappedHierarchy[chapterId].subchapters[subchapterId]
        .openInPractice
    },
    async toggleBlockChapter(chapterId: number) {
      const groupStore = useGroupStore()
      const groupId = groupStore.group.id

      if (!groupId) {
        throw new Error('No group loaded')
      }
      const chapter = this.mappedHierarchy[chapterId]

      if (chapter.openInPractice) {
        await groupPracticeSubchapterBlock(groupId, chapterId) // BE changes all subchapters to locked
        chapter.openInPractice = false
      } else {
        await groupPracticeSubchapterUnblock(groupId, chapterId) // BE changes all subchapters to unlocked
        chapter.openInPractice = true
      }

      // change all subchapters to the same state of the chapter
      Object.values(chapter.subchapters).forEach((subchapter) => {
        subchapter.openInPractice = chapter.openInPractice
      })

      if (groupStore.group.settings.books[0].bookSeriesId) {
        // update hierarchy state from BE
        // needed in case of bookseries when changing book in order to get the latest openInPractice state
        this.hierarchy = await this.getHierarchy()
      }
    },
    async toggleBlockSubchapter(chapterId: number, subchapterId: number) {
      const groupStore = useGroupStore()
      const groupId = groupStore.group.id

      if (!groupId) {
        throw new Error('No group loaded')
      }

      const chapter = this.mappedHierarchy[chapterId]

      const subchapter = chapter.subchapters[subchapterId]

      if (subchapter.openInPractice) {
        await groupPracticeSubchapterBlock(groupId, subchapterId)
        subchapter.openInPractice = false
      } else {
        await groupPracticeSubchapterUnblock(groupId, subchapterId)
        subchapter.openInPractice = true
      }

      // update chapter state depending on if there is some open subchapter
      const someSubchaptersOpen = Object.values(chapter.subchapters).some(
        (subchapter) => subchapter.openInPractice
      )

      if (someSubchaptersOpen) {
        chapter.openInPractice = true
      } else {
        chapter.openInPractice = false
      }

      if (groupStore.group.settings.books[0].bookSeriesId) {
        // update hierarchy state from BE
        // needed in case of bookseries when changing book in order to get the latest openInPractice state
        this.hierarchy = await this.getHierarchy()
      }
    },
    isHierarchyOpenForPractice(usedIn: UsedInLocation[]) {
      let foundUsedIn
      if (this.hierarchy.type === 'BOOKSERIES') {
        foundUsedIn = usedIn.find(
          (used) =>
            used.curriculum.id === this.hierarchy.curriculum.id &&
            used.subject.id === this.hierarchy.subject.id &&
            used.course.id === this.hierarchy.course.id &&
            used.book.id === this.store.seriesBookId
        )
      } else {
        foundUsedIn = usedIn.find(
          (used) =>
            used.curriculum.id === this.hierarchy.curriculum.id &&
            used.subject.id === this.hierarchy.subject.id &&
            used.course.id === this.hierarchy.course.id &&
            used.book.id === this.hierarchy.id
        )
      }

      if (!foundUsedIn) {
        return false
      }

      const chapterId = foundUsedIn.chapter.id
      const subchapterId = foundUsedIn.subchapter.id

      if (
        !this.mappedHierarchy[chapterId] ||
        !this.mappedHierarchy[chapterId]?.subchapters[subchapterId]
      ) {
        return false
      }

      return (
        this.mappedHierarchy[chapterId].openInPractice &&
        this.mappedHierarchy[chapterId].subchapters[subchapterId].openInPractice
      )
    },
    setContent(contentIds: Record<string, boolean>, labels: string[]) {
      this.search.contentIds = contentIds
      this.search.contentLabels = labels
    },
    clearContentSelection() {
      this.search.contentIds = null
      this.search.contentLabels = []
    },
    setSeriesBookId(id: number) {
      this.search.seriesBookId = id
      this.mappedHierarchy = this.mapHierarchy(this.hierarchy)
    },
    setFacets(facets: any) {
      this.search.facets = facets
    },
    setPageIndex(index: number) {
      this.search.page = index
    },
    setPageSize(size: number) {
      this.search.pageSize = size
    },
    clear() {
      this.store = initialStore()
      this.hierarchy = BLANK_HIERARCHY
      this.mappedHierarchy = {} as MappedHierarchy
      this.blockedQuestions = []
    },
  },
})
