import {
  GeogebraType,
  ExamPartStatus,
  ExamPartStatusAfterSubmission,
  ExamAnswerArea,
  TranslationType,
  SpellCheckLanguage,
  ExamNetPartState,
} from '@/constants'

export type Config = {
  parts: number
  geogebra?: GeogebraType[]
  desmos?: number[]
  calculator?: number[]
  separatePaper?: number[]
}

export type MinimalExamPart = Pick<ExamPart, 'tools' | 'config'>

export function previousPartToolDifference(
  prevTools?: ExamPartTools,
  currentTools?: ExamPartTools
) {
  const scienceTools = ['calculator', 'desmos', 'geogebraType'] as (
    | 'calculator'
    | 'desmos'
    | 'geogebraType'
  )[]

  let toolsAdded = false
  let toolsRemoved = false

  scienceTools.forEach((tool) => {
    const was = prevTools?.science[tool] ?? false
    const now = currentTools?.science[tool] ?? false

    if (tool === 'geogebraType') {
      const changes = handleGeogebraChange(String(was), String(now))
      toolsAdded = toolsAdded || changes.added
      toolsRemoved = toolsRemoved || changes.removed
    } else {
      toolsRemoved = toolsRemoved || (Boolean(was) && !now)
      toolsAdded = toolsAdded || (!was && Boolean(now))
    }
  })

  return { toolsAdded, toolsRemoved }
}

type ToolChanges = { added: boolean; removed: boolean }

function handleGeogebraChange(was: string, now: string): ToolChanges {
  return {
    added: was === 'NONE' && now !== 'NONE', // Geogebra is added or changed to another version
    removed: was !== 'NONE' && now === 'NONE', // Geogebra is removed or changed to another version
  }
}

export const generateExamSettings = (config: Config): MinimalExamPart[] => {
  // convert Config to [{geogebra: true, desmos: false, calculator: false}, ...]
  // config.parts is the number of parts
  // const pArr = [0, 1, 2]
  const parts = Array.from({ length: config.parts }).map((_, partIndex) => ({
    geogebra: config.geogebra?.at(partIndex) || GeogebraType.NONE,
    desmos: config.desmos?.includes(partIndex) || false,
    calculator: config.calculator?.includes(partIndex) || false,
    answerArea: config.separatePaper?.includes(partIndex)
      ? ExamAnswerArea.SEPARATE
      : ExamAnswerArea.INLINE,
  }))

  return parts.map((part) => ({
    tools: {
      state: ExamPartStatus.OPEN,
      examNetState: ExamNetPartState.WRITABLE,
      partsAfterSubmission: ExamPartStatusAfterSubmission.HIDDEN,
      drawingArea: false,
      camera: false,
      audioRecording: false,
      programming: false,
      science: {
        geogebraType: part.geogebra,
        desmos: part.desmos || false,
        calculator: part.calculator || false,
        formula: false,
      },
      language: {
        spellCheck: SpellCheckLanguage.NONE,
        textToSpeech: false,
        translate: TranslationType.NONE,
        dictionaries: [],
      },
    },
    config: { calculatorAllowed: true, answerArea: part.answerArea },
  }))
}
