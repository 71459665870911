import { useTokenStore } from '@/stores/token'
import { useUserStore } from '@/stores/user'
import { useSearchStore } from '@/stores/search'
import { usePrintStore } from '@/stores/print'
import { useExamsStore } from '@/stores/exams'
import { useExamLibraryStore } from '@/stores/examLibrary'
import { usePracticeStore } from '@/stores/practice'
import { redirectToExamNet } from '@/services/examNet'

export function useRequiresAdminAuthRule() {
  return {
    async beforeEnter(_to: any, _from: any, next: any) {
      const token = useTokenStore()
      if (token.hasValidAdminToken()) {
        next()
      } else {
        next('/admauth')
      }
    },
  }
}

export function useAdminAlreadyHasAuth() {
  return {
    async beforeEnter(_to: any, _from: any, next: any) {
      const token = useTokenStore()
      if (!token.hasValidAdminToken()) {
        next()
      } else {
        next('/admin')
      }
    },
  }
}

export function useRequiresStudentAuthRule() {
  return {
    async beforeEnter(to: any, _from: any, next: any) {
      const tokenStore = useTokenStore()
      if (tokenStore.hasValidStudentToken()) {
        tokenStore.setMode('student')
        next()
      } else {
        tokenStore.logoutStudent()

        next({ path: '/auth', query: { redirect: to.fullPath } })
      }
    },
  }
}

export function useRequiresTeacherAuthRule() {
  return {
    async beforeEnter(to: any, _from: any, next: any) {
      const tokenStore = useTokenStore()
      let dropMasquerade = false
      if (to.query.masquerade) {
        if (!(await tokenStore.ensureMasquerade(Number(to.query.masquerade)))) {
          dropMasquerade = true
        }
      } else {
        await tokenStore.leaveMasquerade()
      }
      if (dropMasquerade)
        next({ ...to, query: { ...to.query, masquerade: undefined } })
      else if (tokenStore.hasValidTeacherToken()) {
        tokenStore.setMode('teacher')
        next()
      } else {
        tokenStore.logoutTeacher()
        useUserStore().clear()
        useSearchStore().reset()
        usePrintStore().clear()
        useExamsStore().clear()
        useExamLibraryStore().clear()
        usePracticeStore().clear()
        next({ path: '/auth', query: { redirect: to.fullPath } })
      }
    },
  }
}

export function useAlreadyHasAuthRule() {
  return {
    async beforeEnter(to: any, _from: any, next: any) {
      const token = useTokenStore()
      if (token.hasValidTeacherToken()) {
        next('/home')
      } else if (token.hasValidStudentToken()) {
        token.setMode('student')
        if (to.query.examkey) {
          await redirectToExamNet(to.query.examkey)
        } else {
          window.location.href = '/student'
        }
      } else {
        next()
      }
    },
  }
}
