import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import { PageSize, FIRST_PAGE_INDEX, SORT_BY_NAME_KEY } from '@/constants'
import type { ExamsCategory } from '@/constants'
import { getLibraryCards } from '@/services/user'
import { useSessionCacheStore } from '@/stores/sessionCache'
import { getExamLibraryCards } from '@/views/exam-library/examLibraryCards'

type LibraryCardsCategory = Record<
  ExamsCategory,
  { subjectsWithContent: number[] }
>

type FacetItem = {
  id: string | number
  name: string
}

type MinMax = { min: number; max: number }

type Facets = {
  book: FacetItem[]
  sources: FacetItem[]
  chapters: FacetItem[]
  points: MinMax
  questions: MinMax
  // abilities: FacetItem[]
}

type QueryParams = {
  query: string
  sortBy: string
  courseId: string
  autocorrect: string
  calculator: string
  subChapters: string[]
  points: number[]
  questions: number[]
  categoryId: string
  page: number
  pageSize: number
  forceSearch: number
  // abilities: string[]
}

type Store = {
  params: QueryParams
  facets: Facets | null
  page: number
  pageSize: number
  tableSortBy: { field: string; order: number }
  cardCategoriesSubjects: LibraryCardsCategory
  libraryCards: ExamLibraryCard[]
}

const IGNORED_PARAMS = ['query', 'sortBy', 'page', 'pageSize', 'forceSearch']

const BLANK_PARAMS = {
  query: '',
  sortBy: SORT_BY_NAME_KEY,
  autocorrect: '',
  calculator: '',
  // abilities: [],
  subChapters: [],
  courseId: '',
  categoryId: '',
  points: [],
  questions: [],
  forceSearch: 0,
}

const initialStore = (): Store => ({
  params: JSON.parse(JSON.stringify(BLANK_PARAMS)),
  facets: null,
  page: FIRST_PAGE_INDEX,
  pageSize: PageSize.NORMAL,
  tableSortBy: { field: 'name', order: -1 },
  cardCategoriesSubjects: {} as LibraryCardsCategory,
  libraryCards: [],
})

export const useExamLibraryStore = defineStore('examLibrary', {
  state: () => ({
    store: useSessionStorage('examSearch', initialStore()),
  }),
  getters: {
    search: (state) => {
      if (!state.store) {
        state.store = initialStore()
      }
      return state.store
    },
    queryParams(): QueryParams {
      return {
        query: this.search.params.query,
        sortBy: this.search.params.sortBy,
        courseId: this.search.params.courseId,
        autocorrect: this.search.params.autocorrect,
        calculator: this.search.params.calculator,
        points: this.search.params.points,
        questions: this.search.params.questions,
        categoryId: this.search.params.categoryId,
        subChapters: this.search.params.subChapters,
        forceSearch: this.search.params.forceSearch,
        //   abilities: this.search.params.abilities,
        page: this.search.page,
        pageSize: this.search.pageSize,
      }
    },
    numberOfQueryParams(): number {
      const numFilter = Object.entries(this.search.params).reduce(
        (acc: number, [key, value]) => {
          if (
            !IGNORED_PARAMS.includes(key) &&
            (Array.isArray(value) ? value.length > 0 : value)
          ) {
            return acc + 1
          }
          return acc
        },
        0
      )
      return numFilter
    },
    libraryCards(): ExamLibraryCard[] {
      return this.store.libraryCards
    },
  },
  actions: {
    forceSearch() {
      this.search.params.forceSearch++
    },
    setSortBy(field: string | undefined, order: number | null) {
      // order has three states: 1, -1, null (null means no sort)
      const sortOrder = order === 1 ? 'asc' : 'desc'
      const sortField = field || 'name'
      this.search.params.sortBy = `${sortField}-${sortOrder}`
      this.search.tableSortBy = {
        field: sortField,
        order: order === 1 ? 1 : -1,
      }
    },
    setPageIndex(index: number) {
      this.search.page = index
    },
    setPageSize(size: number) {
      this.search.pageSize = size
    },
    setFacets(facets: any) {
      this.search.facets = facets
    },
    setPoints(points: number[]) {
      const [min, max] = points
      if (min === 0 && max === 100) {
        points = []
      }
      this.search.params.points = points
    },
    setNumQuestions(questions: number[]) {
      const [min, max] = questions
      if (min === 0 && max === 100) {
        questions = []
      }
      this.search.params.questions = questions
    },
    async loadLibaryCards() {
      this.store.cardCategoriesSubjects = await getLibraryCards()
      this.store.libraryCards = this.getLibraryCardsByCategory()
    },
    getLibraryCardsByCategory() {
      const categories = Object.keys(this.filterCardsByCurriculumSubjectIds())
      return getExamLibraryCards(categories as ExamsCategory[])
    },
    getCurriculumSubjectsByCategory(category: ExamsCategory) {
      const sessionCacheStore = useSessionCacheStore()
      const categories = this.filterCardsByCurriculumSubjectIds()

      const subjectIds = categories[category]?.subjectsWithContent

      if (!subjectIds) {
        // category may not be in the object categories depending on BE response
        // then we don't filter curriculum.subjects
        return sessionCacheStore.curriculum.subjects
      }

      return sessionCacheStore.curriculum.subjects.filter((subject) =>
        subjectIds.includes(subject.id)
      )
    },
    filterCardsByCurriculumSubjectIds(): LibraryCardsCategory {
      const sessionCacheStore = useSessionCacheStore()

      const subjectsIds = new Set(
        sessionCacheStore.curriculum.subjects.map((subject) => subject.id)
      )

      const result = Object.entries(this.store.cardCategoriesSubjects).reduce(
        (acc, [category, obj]) => {
          const filteredSubjects = obj.subjectsWithContent.filter((subjectId) =>
            subjectsIds.has(subjectId)
          )
          if (filteredSubjects.length) {
            acc[category as ExamsCategory] = {
              subjectsWithContent: filteredSubjects,
            }
          }
          return acc
        },
        {} as LibraryCardsCategory
      )

      return result
    },
    clear(keepCards = false) {
      let libraryCards = [] as ExamLibraryCard[]
      let cardCategoriesSubjects = {} as LibraryCardsCategory
      if (keepCards) {
        // used in watch() when changing subject from exam library table so we don't loose the cards
        libraryCards = JSON.parse(JSON.stringify(this.store.libraryCards))
        cardCategoriesSubjects = JSON.parse(
          JSON.stringify(this.store.cardCategoriesSubjects)
        )
      }
      this.store = initialStore()
      this.store.libraryCards = libraryCards
      this.store.cardCategoriesSubjects = cardCategoriesSubjects
    },
  },
})
