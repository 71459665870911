import { httpStudent } from './http'
import { extractExamErrorDetails } from '@/utils/extractExamErrorDetails'

export async function redirectToExamNet(examkey) {
  try {
    const res = await httpStudent.post(`/v1/exam-net/${examkey}/start`)
    window.location.href = res.data.url // Redirect to exam on exam.net
  } catch (err) {
    const error =
      err.response?.status === 422
        ? { errors: err.response.data.errors, status: err.response.status }
        : { status: 500 }

    const { errorType } = extractExamErrorDetails(error)
    window.location.href = `/student?error=${errorType}`
  }
}
