import { defineStore } from 'pinia'
import { useLocalStorage, StorageSerializers } from '@vueuse/core'
import { useSessionCacheStore } from '@/stores/sessionCache'
import { getUser } from '@/services/user'
import {
  settingsGetAll,
  settingsUpdatePartial,
  settingsSetCurriculum,
} from '@/services/settings'
import { UserOrganizationRole, UserPermissions, UserType } from '@/constants'
import { API } from '@/km/services/API'
import kmExamFacade from '@/km/services/KMExamFacade'
import examFacade from '@/services/ExamFacade'
import Bugsnag from '@bugsnag/js'
import { updateFieldByPath } from '@/utils/updateFieldByPath'

const BLANK_USER: KMUser = {
  userId: 0,
  firstName: '',
  lastName: '',
  fullName: '',
  country: '',
  language: 'en',
  status: '',
  permissions: [],
  organization: { id: 0, name: '', role: UserOrganizationRole.TEACHER },
  returnUrl: '',
  settings: {
    curriculumId: 0,
  },
}

export const useUserStore = defineStore('user', {
  state: () => ({
    gaussUser: null as User | null,
    kmUser: useLocalStorage('kmUser', null as KMUser | null, {
      serializer: StorageSerializers.object,
    }),
    // set before each route change
    km: null as boolean | null,
    dirty: false,
  }),
  getters: {
    user(state): User | KMUser {
      const user = state.km ? state.kmUser : state.gaussUser
      if (!user) {
        return BLANK_USER
      }
      return user
    },
    userSettings(): UserSettings {
      return this.user.settings
    },
    hasUnsavedChanges(): boolean {
      return this.dirty
    },
    returnUrl(state) {
      return state.kmUser?.returnUrl
    },
    facade(state) {
      return state.km ? kmExamFacade : examFacade
    },
    allowedToSeeMetadata(): boolean {
      return this.user.permissions.includes(UserPermissions.ViewMetadata)
    },
    isStudent(): boolean {
      return this.user.status === UserType.STUDENT
    },
    isTeacher(): boolean {
      return this.user.status === UserType.TEACHER
    },
    haveToChooseCurriculum(): boolean {
      return !this.user?.settings?.curriculumId
    },
    admin(): boolean {
      return this.user.permissions.includes(UserPermissions.ViewAdminUI)
    },
    isSchoolAdmin(): boolean {
      // Students does not have organization
      return (
        this.user.organization?.role === UserOrganizationRole.ADMIN ||
        this.user.organization?.role === UserOrganizationRole.OWNER
      )
    },
  },
  actions: {
    userHasPermission(permission: UserPermissions): boolean {
      return this.user?.permissions?.includes(permission) ?? false
    },
    async setKMMode(km: boolean) {
      this.km = km
      await useSessionCacheStore().updateCache()
    },
    async loadGaussUser() {
      const userData = await getUser()
      this.gaussUser = {
        userId: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        fullName: `${userData.firstName} ${userData.lastName}`,
        country: userData.countryId,
        language: userData.languageId,
        loginType: userData.loginType,
        status: userData.status,
        permissions: userData.permissions.map((p: any) => p.name),
        organization: userData.organizations.map((o: any) => ({
          id: o.id,
          name: o.name,
          role: o.role,
        }))[0],
        settings: await settingsGetAll(),
      }

      await useSessionCacheStore().onLogin()

      Bugsnag.setUser(String(this.gaussUser.userId))
    },
    async loadKMUser(teacherId: number, schoolId: number, returnUrl: string) {
      const response = (await API.getTeacher(teacherId)) as any
      const teacherData = response.data.sqldata[0]
      const permissions = [UserPermissions.EditQuestions]
      if (teacherData.trust >= 2) {
        permissions.push(UserPermissions.EditAllQuestions)
      }
      if (teacherData.trust >= 8) {
        permissions.push(UserPermissions.ViewMetadata)
      }
      this.kmUser = {
        userId: teacherId,
        firstName: teacherData.firstname,
        lastName: teacherData.lastname,
        fullName: `${teacherData.firstname} ${teacherData.lastname}`,
        country: 'sv',
        language: 'sv',
        status: UserType.TEACHER,
        permissions,
        organization: {
          id: schoolId,
          name: 'FIXME',
          role: UserOrganizationRole.TEACHER,
        },
        returnUrl,
        settings: {
          curriculumId: 1,
        },
      }
      await useSessionCacheStore().onLogin()
      Bugsnag.setUser(String(teacherId))
    },
    async getCurrentCurriculum() {
      if (!this.user.settings?.curriculumId) {
        Bugsnag.notify(new Error('No curriculumId in settings'))
        return null
      }
      try {
        return await this.facade.getCurriculumById(
          this.user.settings.curriculumId
        )
      } catch (e: any) {
        if (e?.status === 404) {
          // User has a bad setting, reset it
          this.user.settings.curriculumId = 0
        } else {
          throw e
        }
      }
    },
    async setMyCurriculum(curriculumId: number) {
      if (!curriculumId) {
        Bugsnag.notify(new Error('Invalid curriculumId cannot be set'))
        return
      }
      if (this.user.settings.curriculumId !== Number(curriculumId)) {
        await settingsSetCurriculum(curriculumId)
        const sessionCacheStore = useSessionCacheStore()
        this.user.settings.curriculumId = curriculumId
        await sessionCacheStore.updateCurriculum()
      }
    },
    async loadUserSettings() {
      const settings = await settingsGetAll()
      this.user.settings = settings
    },
    updateSettings(field: string[], value: any) {
      updateFieldByPath(this.user.settings, field, value)
      this.dirty = true
    },
    async saveUserSettings() {
      await settingsUpdatePartial(this.user.settings)
    },
    setDirtyState(value: boolean) {
      this.dirty = value
    },
    async updateUserCache() {
      if (this.km) {
        // KM uses local storage for user data
        return
      }
      await this.loadGaussUser()
    },
    async onLogin() {
      await this.updateUserCache()
    },
    async onReload() {
      await this.updateUserCache()
    },
    async onUserSwitch() {
      await this.updateUserCache()
    },
    clear() {
      if (this.km) {
        this.kmUser = null
      } else {
        this.gaussUser = null
      }
    },
  },
})
