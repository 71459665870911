import { http, httpShared, throwOnAxiosError } from './http'

export async function getUser() {
  try {
    const response = await httpShared.get(`/v1/profile`)
    return response.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve user data')
  }
}

export async function getMasqueradeToken(id) {
  try {
    const response = await http.post(`/admin/users/login/${id}`)
    return response.data
  } catch (err) {
    throw new Error('Failed to perform action')
  }
}

export async function getLibraryCards() {
  try {
    const response = await http.get(`/v1/me/library-cards`)
    return response.data
  } catch (err) {
    throw new Error('Failed to retrieve library cards')
  }
}
