interface ExamErrorDetails {
  errorType: string
}

/**
 * Extracts error type from exam redirect error responses
 *
 * @param error Error object from redirectToExamNet
 * @returns Object containing errorType
 */
export function extractExamErrorDetails(error: any): ExamErrorDetails {
  let errorType = 'unknown'

  try {
    if (error.status === 422) {
      if (error.errors?.examkey && Array.isArray(error.errors.examkey)) {
        if (error.errors.examkey[0] === 'access_denied') {
          errorType = 'access_denied'
        } else if (error.errors.examkey[0] === 'already_submitted') {
          errorType = 'already_submitted'
        } else {
          errorType = 'validation'
        }
      } else {
        errorType = 'validation'
      }
    } else if (error.status === 500) {
      errorType = 'server'
    }
  } catch (e) {
    console.error('Failed to extract exam error details', e)
  }

  return { errorType }
}
