import {
  examGetById,
  examGetByUUId,
  examSave,
  examCreate,
  examGetByIdStudent,
  type ExamForSaving,
} from '@/services/exam'
import { getTotalPointsPerGrade } from '@/utils/getTotalGradePointsPerAbility'
import {
  Difficulty,
  ExamType,
  ExamStatus,
  ExamPartStatus,
  ExamPartStatusAfterSubmission,
  GeogebraType,
  SpellCheckLanguage,
  TranslationType,
} from '@/constants'
import { getDifficulty } from '@/utils/difficulty'
import type { subQuestionV2 } from '@/utils/questionConverter'
import type { DifficultyPoint } from 'gauss-matrix'

async function getExamById(id: number, anonymous = false): Promise<Exam> {
  const exam = await examGetById(id, anonymous)
  return mapExamFromBackend(exam)
}
async function getExamByUUId(uuid: string): Promise<Exam> {
  const exam = await examGetByUUId(uuid)
  return mapExamFromBackend(exam)
}

type StudentExam = Exam

async function getStudentExamById(
  id: number,
  anonymous = false
): Promise<StudentExam> {
  const exam = await examGetByIdStudent(id, anonymous)
  return mapExamFromBackend(exam)
}

async function saveExam(exam: Exam): Promise<Exam> {
  const partsForSaving = exam.parts.map((part) => {
    const { questions, ...rest } = part
    return {
      ...rest,
      // Questions are not sent for optimization purposes when changing part settings only
      questionVersionIds: questions?.map((q) => q.primaryId),
    }
  })

  if ('date' in exam.settings.basic) {
    // temporary fix for backend until they delete the field permanently from all exams
    delete exam.settings.basic.date
  }

  const examForSaving: ExamForSaving = {
    name: exam.name,
    type: exam.type,
    group: exam.group,
    material: exam.material,
    settings: exam.settings,
    examinationAt: exam.examinationAt,
    status: exam.status,
    parts: partsForSaving,
    addedBlocks: exam.addedBlocks,
    removedBlocks: exam.removedBlocks,
    syncFailed: exam.syncFailed,
    anonymizeStudents: exam.anonymizeStudents,
  }
  const savedExam = await examSave(exam.id, examForSaving)
  return mapExamFromBackend(savedExam)
}

async function createExam(exam: Exam, copiedFrom: number) {
  const examForSaving = {
    name: exam.name,
    type: exam.type,
    group: exam.group,
    material: exam.material,
    category: exam.category,
    copiedFrom,
    settings: exam.settings,
    examinationAt: exam.examinationAt,
    status: ExamStatus.CLOSED,
    parts: exam.parts.map((part) => {
      const { questions, ...rest } = part
      return {
        ...rest,
        questionVersionIds: questions.map((q) => q.primaryId),
      }
    }),
    addedBlocks: exam.addedBlocks,
    removedBlocks: exam.removedBlocks,
    syncFailed: false,
    anonymizeStudents: exam.anonymizeStudents,
  }
  const examCreated = await examCreate(examForSaving)

  return mapExamFromBackend(examCreated)
}

type AbilityMap = {
  [key: string]: string
}

function mapQuestion(q: any, abilityMap: AbilityMap, bookId: number): Question {
  if (bookId) {
    injectChapter(q, bookId)
  }
  const primaryId = q.questionVersionId
  const displayId = q.id + '-' + q.version
  const criterias = mapCriterias(
    q.questionVersionId,
    abilityMap,
    q.content,
    q.context
  )
  const availablePoints = mapAvailablePoints(criterias)
  const difficulty = getQuestionDifficulty(criterias)
  const usedInExams = mapExamsUsedInQuestion(q)
  return {
    ...q,
    primaryId,
    displayId,
    criterias,
    availablePoints,
    difficulty,
    usedInExams,
  }
}

type LegacyExamPartSettings = ExamPartConfig & {
  digital?: {
    heading: string
    answerArea: string
  }
  print?: {
    heading: string
    answerArea: string
  }
}

export function fixLegacyExamPartSettings(
  partSettings: LegacyExamPartSettings
): ExamPartConfig {
  if (partSettings.digital === undefined) {
    return partSettings
  }
  const newPartSettings = JSON.parse(JSON.stringify(partSettings))
  const digitalHeading = partSettings.digital.heading // it can be null also
  const digitalAnswerArea = partSettings.digital.answerArea // will be undefined next time after saving the exam

  newPartSettings.heading = digitalHeading || partSettings.heading || ''
  newPartSettings.answerArea = digitalAnswerArea || partSettings.answerArea
  delete newPartSettings.digital
  delete newPartSettings.print

  return newPartSettings
}

export const BLANK_TOOLS: ExamPartTools = {
  state: ExamPartStatus.OPEN,
  examNetState: ExamPartStatus.OPEN,
  partsAfterSubmission: ExamPartStatusAfterSubmission.HIDDEN,
  drawingArea: false,
  camera: false,
  audioRecording: false,
  programming: false,
  science: {
    geogebraType: GeogebraType.NONE,
    desmos: false,
    calculator: false,
    formula: false,
  },
  language: {
    spellCheck: SpellCheckLanguage.NONE,
    textToSpeech: false,
    translate: TranslationType.NONE,
    dictionaries: [],
  },
}

function fixLegacyExamTools(tools: any) {
  if (!tools) {
    return BLANK_TOOLS
  }
  return tools
}

function mapExamFromBackend(exam: any): Promise<Exam> {
  const abilityMap = getAbilityMapFromExam(exam)
  exam.parts.forEach((part: any) => {
    part.questions = part.questions.map((q: any) => {
      return mapQuestion(q, abilityMap, exam.material.id)
    })
    part.config = fixLegacyExamPartSettings(part.config)
    part.tools = fixLegacyExamTools(part.tools)
  })

  return mapExam(exam)
}

function mapExamsUsedInQuestion(q: any): Exam[] {
  return q.context.usedInExams.map((exam: any) => {
    return mapExam(exam)
  })
}

function mapExam(exam: any): Promise<Exam> {
  exam.type = exam.type || ExamType.Exam
  return exam
}

function getAbilityMapFromExam(exam: Exam): AbilityMap {
  return exam.course.abilities.reduce((obj, item) => {
    return {
      ...obj,
      [item.id]: item.name,
    }
  }, {})
}

type Criterium = {
  criteriaIndex: number
  subchapterId: number
  subchapterName: string
  questionPrimaryId: number
  subQuestionId: number
  pointType: string
  pointIndex: number
  ability: string
  abilityKey: string
  points: number
}

function mapCriterias(
  primaryId: number,
  abilityMap: AbilityMap,
  content: subQuestionV2[],
  context: QuestionContext
): Criterium[] {
  const pointTypes = getPointTypes()
  // V2 content assumption
  return content.flatMap((sq) =>
    (sq.data.criterias || []).map((criteria, index) => ({
      criteriaIndex: index,
      subchapterId: context.subchapter?.id ?? 0, // O in case when question is not in the same book as the exam
      subchapterName: context.subchapter?.name ?? '',
      questionPrimaryId: primaryId,
      subQuestionId: sq.id,
      pointType: criteria.level,
      pointIndex: pointTypes.findIndex((pt) => pt.key === criteria.level),
      ability: abilityMap[criteria.ability],
      abilityKey: criteria.ability,
      points: 1,
    }))
  )
}

function mapAvailablePoints(criterias: Criterium[]): DifficultyPoint {
  return criterias.reduce(
    (total, criteria) => {
      total[criteria.pointIndex]++
      return total
    },
    [0, 0, 0]
  )
}

function injectChapter(q: Question, bookId: number) {
  const location = q.context.usedIn?.find(
    (location) =>
      location.bookseries?.id === Number(bookId) ||
      location.book.id === Number(bookId)
  )

  if (location) {
    q.context.chapter = location.chapter
    q.context.subchapter = location.subchapter
    q.context.course = location.course
  }
}

function getPointTypes() {
  return [
    { key: 'e', name: Difficulty.EASY },
    { key: 'c', name: Difficulty.MEDIUM },
    { key: 'a', name: Difficulty.DIFFICULT },
  ]
}

const getQuestionDifficulty = (criterias: Criterium[]) => {
  const pointTypes = getPointTypes().map((level) => level.key)
  const totalPointsPerGradeResult = getTotalPointsPerGrade(
    pointTypes,
    criterias
  )
  const { weightedMeanValue } = getDifficulty(totalPointsPerGradeResult)
  return weightedMeanValue
}

export default {
  getExamById,
  getExamByUUId,
  getStudentExamById,
  saveExam,
  createExam,
  mapQuestion,
}
