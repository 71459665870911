import { http, httpShared, throwOnAxiosError } from './http'

export async function attachmentsGet(attachmentIds: number[]) {
  try {
    const res = await httpShared.post(`/v1/attachments/bulk`, {
      attachmentIds,
    })
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to retrieve question attachmens by id')
  }
}

export async function attachmentReport(attachmentId: number, data: any) {
  try {
    const res = await http.post(
      `/v1/me/attachments/${attachmentId}/report`,
      data
    )
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to report attachment')
  }
}
